<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-row :gutter="16" style="padding: 5px 10px 20px 10px">
        <a-col :span="4">
          <a-statistic title="总计" :value="(this.data.balance / 100).toFixed(2)">
          </a-statistic>
        </a-col>
        <a-col :span="5">
          <a-statistic :value="(this.data.bal1 / 100).toFixed(2)">
            <template #title>
              <span>账户：8661177810142100<span style="font-weight: bolder;color: #666666">4588</span></span>
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="5">
          <a-statistic :value="(this.data.bal2 / 100).toFixed(2)">
            <template #title>
              <span>账户：8661177810142100<span style="font-weight: bolder;color: #666666">4571</span></span>
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="5">
          <a-statistic :value="(this.data.bal3 / 100).toFixed(2)">
            <template #title>
              <span>账户：8661102910142102<span style="font-weight: bolder;color: #666666">6547</span></span>
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="5">
          <a-statistic :value="(this.data.bal4 / 100).toFixed(2)">
            <template #title>
              <span>账户：8661173110142100<span style="font-weight: bolder;color: #666666">4169</span></span>
            </template>
          </a-statistic>
        </a-col>
      </a-row>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :showPagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right;">
        {{ (text / 100).toFixed(2) }}
      </div>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import { current_property_balance } from '@/api/property_balance'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      data: {},
      loading: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.PaymentTicketCategory[text] || '银行充值',
          align: 'center',
          ellipsis: true
        },
        {
          title: '账户',
          dataIndex: 'property_owner',
          customRender: (text) => '*' + this.$Dictionaries.property_owner[text].substr(this.$Dictionaries.property_owner[text].length - 4, this.$Dictionaries.property_owner[text]),
          align: 'center',
          ellipsis: true
        },
        {
          title: '金额(元)',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'amount',
          align: 'right'
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          customRender: (text) => text || '-',
          align: 'center',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return current_property_balance(this.$Dictionaries.corporation_id)
          .then(res => {
            this.data = res.data
            console.log(res.data, '我是数据列表@')
            return { entries: res.data.detail }
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    }
  }
}
</script>
